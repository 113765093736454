/* colors */
$dark-purple: hsla(283, 63%, 6%, 1);
$highlight-purple: hsla(284, 61%, 26%, 1);
$highlight-purple-light: hsla(284, 69%, 56%, 1);
$highlight-purple-light-10: hsla(284, 69%, 56%, .1);
$highlight-purple-light-10-white: hsla(281, 73%, 96%, 1);
$highlight-purple-light-30: hsla(284, 69%, 56%, .3);
$highlight-purple-light-30-white: hsla(283, 70%, 87%, 1);
$highlight-purple-light-50: hsla(284, 69%, 56%, .5);
$highlight-purple-light-50-white: hsla(284, 70%, 78%, 1);
$white: hsla(284, 61%, 94%, 1);
$black: hsla(284, 61%, 4%, 1);
$highlight-red: hsla(334, 69%, 56%, 1);
$super-light-red: hsla(334, 61%, 94%, 1);

/* new colors */
$background: hsla(284, 0%, 24%, 1);
$white-new: hsla(284, 0%, 100%, 1);
$background-dark: hsla(0, 0%, 14%, 1);

/* type */
$font-rubik: "Rubik", sans-serif;
$font-rubik-one: "Rubik Mono One", sans-serif;
