.LoaderButton {
  font-size: 1rem;
  outline: none;
  padding: 8px;
  border-radius: 4px;
  height: 40px;
  width: 50%;
  cursor: pointer;
}

.LoaderButton .spinning {
  margin-right: 7px;
  top: 2px;
  animation: spin 1s infinite linear;
  width: 50px;
  height: 50px;
}

.LoaderButton:disabled {
  cursor: default;
}

@keyframes spin {
  from {
    transform: scale(1) rotate(0deg);
  }
  to {
    transform: scale(1) rotate(360deg);
  }
}
