@import "../../styles/tokens";

@keyframes shrink {
  from {
    transform: scale(1);
  }
  
  to {
    transform: scale(0);
  }
}

.c-task {
  background-color: $white-new;
  color: $black;
  border-radius: 12px;
  border-color: $black;
  margin-bottom: 16px;
  border-style: solid;
  border-width: 4px;
  filter: opacity(0.5);
  max-width: 640px;

  &__draggable-container {
    flex: 9;
    display: flex;
  }

  &__drag-handle {
    width: 36px;
    position: relative;

    &-bars {
      position: absolute;
      top: 50%;
      height: 20px;
      margin-top: -10px;
      margin-left: 16px;
    }

    &-bar {
      height: 4px;
      width: 12px;
      background-color: $black;
      margin-bottom: 4px;
    }
  }

  &-finishing {
    border-color: $highlight-purple-light;
    background-color: $highlight-purple-light-10-white;
    animation-duration: 250ms;
    animation-name: shrink;
    animation-timing-function: cubic-bezier(0.7, 0, 0.84, 0);
  }

  &-finishing &__buttons {
    display: none;
  }

  &-finished {
    display: none;
  }

  &-deleting {
    border-color: $highlight-red;
    background-color: $super-light-red;
    animation-duration: 250ms;
    animation-name: shrink;
    animation-timing-function: cubic-bezier(0.7, 0, 0.84, 0);
  }

  &-deleting &__buttons {
    display: none;
  }

  &-deleted {
    display: none;
  }
  
  &__primary {
    filter: opacity(1);
    margin-left: auto;
    margin-right: auto;
  }

  &__secondary {
    margin-left: auto;
    margin-right: auto;
  }

  &__container {
    display: flex;
    flex-direction: row;
  }

  &__title {
    font-size: 1rem;
    padding: 24px 8px 24px 24px;
    max-width: 640px;
    flex: 9;

    &:hover {
      cursor: pointer;
    }
    
    &-finishing {
      font-size: 1rem;
      padding: 24px 8px 24px 24px;
      max-width: 640px;
      flex: 9;
    }

    &-finishing:after {
      overflow: hidden;
      display: inline-block;
      vertical-align: bottom;
      -webkit-animation: ellipsis steps(4,end) 1200ms infinite;      
      animation: ellipsis steps(4,end) 1200ms infinite;
      content: "\2026"; /* ascii code for the ellipsis character */
      width: 0px;
    }

    &-deleting {
      font-size: 1rem;
      padding: 24px 8px 24px 24px;
      max-width: 640px;
      flex: 9;
    }

    &-deleting:after {
      overflow: hidden;
      display: inline-block;
      vertical-align: bottom;
      -webkit-animation: ellipsis steps(4,end) 1200ms infinite;      
      animation: ellipsis steps(4,end) 1200ms infinite;
      content: "\2026"; /* ascii code for the ellipsis character */
      width: 0px;
    }
  }

  &__buttons {
    display: flex;
    min-width: 172px;
    margin: 0px 0px 0px -172px;
    padding: 16px 16px 16px 0px;
    border-radius: 12px;
  }

  &__button {
    height: 40px;
    width: 40px;
    position: relative;
  }

  &__button:hover {
    background-color: $highlight-purple-light-10;
    border-color: $highlight-purple-light;
    border-style: solid;
    border-radius: 50%;
    border-width: 2px;
    height: 40px;
    width: 40px;
    cursor: pointer;
  }

  &__button:active {
    background-color: $highlight-purple-light-30;
  }

  &__icon-container {
    display: none;
    position: absolute;
    width: 24px;
    height: 24px;
    margin: -12px auto 0 -12px;
    top: 50%;
    left: 50%;
  }

  &__icon {
    &-svg {
      fill: $black;
    }
  }

  &:hover {
    border-color: $highlight-purple-light;
    filter: opacity(1);
  }

  &:hover &__buttons {
    background: linear-gradient(to bottom, $white-new 40px, hsla(0, 0%, 100%, 0%));
  }

  &:hover &__icon-container {
    display: block;
  }

  &__finish-button button {
    display: none;
    background-color: $white;
    height: 32px;
    min-width: 84px;
    color: $black;
    border-color: $black;
    border-style: solid;
    border-radius: 4px;
    margin-top: 4px;
    margin-left: 8px;
    font-weight: 900;
  }

  &:hover &__finish-button button {
    display: block;
    cursor: pointer;
  }

  &__finish-button button:hover {
    background-color: $highlight-purple-light-30-white;
    border-color: $highlight-purple-light;
  }

  &__finish-button button:active {
    background-color: $highlight-purple-light-50-white;
  }
}

// https://codepen.io/thetallweeks/pen/yybGra?editors=1100
@keyframes ellipsis {
  to {
    width: 1.25em;    
  }
}

@-webkit-keyframes ellipsis {
  to {
    width: 1.25em;    
  }
}