@import "../../styles/tokens";

.c-navigation {
  margin: 0;
  padding: 0;
  background-color: $dark-purple;
  color: $white;
  width: 100%;
  height: 48px;
  display: flex;
  justify-content: space-between;

  &_logo {
    align-self: center;
    width: 32px;
    height: 32px;
    margin-left: 16px;
  }

  &_links {
    margin: 0;
    height: 48px;
    display: flex;
    justify-content: flex-end;
    padding-right: 16px;
  }

  &_link {
    padding-right: 8px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  &_avatar {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  &_picture {
    background-color: darken($white, 20);
    width: 28px;
    height: 28px;
  }

  &_link-text {
    font-size: 1rem;
    color: $white;
    text-decoration: none;
  }

  &_purple-bar {
    height: 10px;
    background-color: $highlight-purple;
  }
}
