/*
    type scale:     https://type-scale.com/
    base size:      16px
    scale:          major third (1.25)
    line height:    1.5
*/

@import "./styles/tokens";

html {
  font-size: 16px;
  line-height: 1.5;
  margin: 0;
  color: $white-new;
  background-color: $background;
  box-sizing: border-box;
}

*, *::before, *::after {
  box-sizing: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-rubik;
  font-weight: 400;
  margin: 0;
}

h1 {
  font-size: 3rem;
}

button,
input {
  font-family: $font-rubik;
  font-weight: 400;
}

div,
ul,
li,
span,
section,
article,
p {
  font-family: $font-rubik;
  font-size: 1.25rem;
}
