.main-grid {
  display: grid;
  @media screen and (min-width: 1000px) {
    grid-template-columns: 435px 1fr 160px;
    /* 176px / font-size: 16px = 11rem */
    margin-top: 11rem;
  }
  @media screen and (max-width: 999px) {
    grid-template-rows: 50px 1fr 0px;
    margin: 32px !important;
  }
  @media screen and (max-width: 320px) {
    grid-template-columns: 1fr;
    grid-template-rows: 150px 1fr;
    margin: 32px !important;
  }
}

.left-column {
  @media screen and (min-width: 1000px) {
    img {
      width: 40px;
      height: 37px;
      padding-top: 16px;
    }

    margin-left: 160px;

    ul {
      margin: 0;
      margin-block-start: 0;
      margin-block-end: 0;
      padding: 0;
    }

    li {
      list-style: none;
    }
  }
  @media screen and (max-width: 999px) {
    img {
      width: 40px;
      height: 37px;
    }

    ul {
      margin: 0;
      margin-block-start: 0;
      margin-block-end: 0;
      padding: 0 0 0 16px;
      display: inline-block;
    }

    li {
      list-style: none;
      display: inline-block;
      padding-left: 16px;
    }
  }
}
