@import "../../styles/tokens";

.c-list {
  width: 640px;
  margin-left: auto;
  margin-right: auto;
  min-height: 88px;

  &__header {
    padding-top: 40px;
    width: 640px;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 0;
    display: flex;
    flex-direction: row;
    height: 76px;

    &-container {
      position: sticky;
      top: 40px;
      width: 720px;
      background-color: $background-dark;
      z-index: 1000;
      border-radius: 12px 12px 0 0;
      margin-left: auto;
      margin-right: auto;
    }

    &__title {
      font-size: 1.5rem;
      padding-left: 24px;
      flex: 1;

      &__later {
        margin-top: 48px;
      }
    }

    &__add-task {
      height: 32px;
      max-width: 84px;
      flex: 1;
      background-color: $white;
      color: $black;
      border-color: $black;
      border-style: solid;
      margin-right: 20px;
      border-radius: 4px;
      border-width: 2px;
      text-transform: uppercase;
      font-weight: 900;
      &:hover {
        cursor: pointer;
        border-color: $highlight-purple-light;
        background-color: $highlight-purple-light-30-white;
      }
    
      &:active {
        cursor: pointer;
        background-color: $highlight-purple-light-50;
      }
    }
  }
}

.firstList {
  padding-top: 16px;
  margin-bottom: 88px;
  position: sticky;
  top: 116px;
  background-color: $background-dark;
  z-index: 1000;
  width: 720px;
  padding-bottom: 24px;
  border-radius: 0 0 12px 12px;
}

.firstList-hightlight {
  background-color: blue;
}

.secondList {
  padding-top: 40px;
  margin-bottom: 64px;
  background-color: $background-dark;
  border-radius: 12px;
  width: 720px;
  padding-bottom: 24px;

  .c-list__header__title {
    margin-left: 40px;
    margin-bottom: 16px;
  }
}

.secondList-hightlight {
  background-color: blue;
}