@import "../../styles/tokens";

.get-started {
  h3,
  h4,
  p {
    font-size: 1.5rem;
  }
}

.top-margin {
  height: 40px;
  top: 0;
  position: sticky;
  background-color: $background;
  z-index: 1000;
}