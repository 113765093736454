.form-grid {
  display: grid;
  margin-top: 48px;
  grid-template-columns: 50%;
  grid-template-rows: repeat(3, 72px);
  grid-row-gap: 16px;
}

.form-lockup {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr;

  label {
    font-family: "Oswald", sans-serif;
    font-weight: 400;
    font-size: 1rem;
    padding-left: 8px;
  }

  input {
    font-family: "Lato", sans-serif;
    font-size: 1rem;
    outline: none;
    padding: 8px;
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    border-color: hsla(0, 0%, 0%, 10%);
  }
}

.error-message {
  color: red;
  padding-left: 8px;
}
